import React, { useState } from "react";
import "./style.css";
import { useUsersContext } from "context/usersContext";
import { useHistory } from "react-router-dom";
import Icon from "components/Icon";

function ChatModal({ processing }) {
  const history = useHistory();
  const { setUserAsUnread, refresh, showToast, SSO, ChatSubmit } = useUsersContext();
  
  // Add state to manage sidebar toggle
  const [isExpanded, setIsExpanded] = useState(false);

  // Handle the click event for scrollchat to toggle sidebar
  const handleToggleSidebar = () => {
    const chatAppElement = document.getElementById("chatapp");
    const sidebar = document.getElementById("sidebar");
    if (chatAppElement) {
      if (isExpanded) {
        chatAppElement.style.width = "0"; // Collapse sidebar
        sidebar.style.display = "block"; // Expand sidebar to 100% width

      } else {

        chatAppElement.style.width = "100%"; // Expand sidebar to 100% width
        sidebar.style.display = "none"; // Expand sidebar to 100% width
      }
      setIsExpanded(!isExpanded); // Toggle state
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      locationId: SSO?.id,
      username: SSO?.chat_username,
    };

    const temp = await ChatSubmit(payload);

    if (!temp?.response) {
      refresh(temp);

      const chatUrl = `/chat/${temp.id}`;
      setUserAsUnread(temp.id);
      history.push(chatUrl);
    } else {
      showToast("Something went wrong!");
    }
  };

  return (
    <div className="chat-dropdown" style={{ display: "flex", justifyContent: "space-between" }}>
      <div
        style={{ cursor: "pointer" }}
        disabled={processing}
        aria-label="Toggle sidebar"
        className="scrollchat"
        onClick={handleToggleSidebar} // Add onClick event to toggle the sidebar
      >
        <Icon id="tooglesidebar" />
      </div>
      <div
        style={{ cursor: "pointer" }}
        disabled={processing}
        aria-label="New chat"
        onClick={handleSubmit}
      >
        <Icon id="newchatIcon" />
      </div>
    </div>
  );
}

export default ChatModal;
