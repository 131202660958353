import React, { useContext, useState, useEffect, useRef } from "react";
import Icon from "components/Icon";
import { PromptsContext } from "context/promptsContext";
import { useUsersContext } from "context/usersContext";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Sidebar2 = ({ text, LoadTaskonchat, assistantData, updateAssistant, toggleSidebar }) => {
  const { promptsData, getPrompts } = useContext(PromptsContext);
  const { users: contacts, SSO, showToast, getLocationUsers, locationUsers, setUserAsUnread } = useUsersContext();

  const [isOpendrop, setIsOpendrop] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [filteredChat, setFilteredChat] = useState([]);
  const [filteredAssistan, setFilteredAssistant] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [activeTab, setActiveTab] = useState('all');

  const switchTab = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    // Filter the prompts based on search query and selected category
    let prompts = promptsData?.prompt !== null && promptsData?.prompt?.filter(item => item.locationId === SSO?.id)
      .filter(item => {
        if (item.set_as === 'personal') {
          return item.userId.some(user => user.id === SSO?.userUUID);
        }
        return false;
      }).filter((prompt) =>
        prompt.title?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        (!selectedCategory?._id || prompt.category === selectedCategory?._id)
      );
    let chats = contacts.filter((chat) =>
      chat.name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
      (!selectedCategory?._id || prompt._id === selectedCategory?._id)
    );
    let filterassistant = assistantData.filter((item) =>
      item.assistant_name?.toLowerCase().includes(searchQuery?.toLowerCase())
    );
    setFilteredPrompts(prompts);
    setFilteredChat(chats);
    setFilteredAssistant(filterassistant);

    if (searchQuery) {
      setActiveTab('all');
    }
  }, [searchQuery, selectedCategory, promptsData]);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);

    if (e.target.value) {
      setActiveTab('all');
    }
  };

  const toggleDropdownd = () => setIsOpendrop(!isOpendrop);

  const SortByCategory = (category) => {
    setSelectedCategory(category);
    setIsOpendrop(false)

  };

  const closeNav = () => {
    document.getElementById("searchsidebar").style.width = "0";
    document.getElementById("tasksidebar").style.width = "0";
    document.getElementById("assistantsidebar").style.width = "0";
  };

  const truncateWords = (text, wordLimit) => {
    const words = text.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return text;
  };

  const dropDownClose = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownClose.current && !dropDownClose.current.contains(event.target)) {
        setIsOpendrop(false)
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDownClose]);

  return (
    <div>
      <div id="searchsidebar" className="v3_sec_sidebar">
        <span className="crossicon" style={{ margin: "0px 6px 0px 0px" }} onClick={closeNav}>
          ×
        </span>
        <div className="v3_searchMain">
          <div className="position-relative" style={{ paddingLeft: "10px" }}>
            <input
              type="text"
              placeholder="Search Brand AI"
              className="v3_search"
              value={searchQuery}
              onChange={handleSearch} // Search input change handler
            />
            <span className="searchiconsetting_v3"><Icon id="v3_search" /></span>
          </div>
          <div style={{ margin: "0px 12px 0px 0px" }} ref={dropDownClose}>
            <div
              className="v3_dropdowninchat_filter position-relative"
              onClick={toggleDropdownd}
            >
              <span
                style={{
                  width: "85%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  textAlign: "right",
                  paddingLeft: "20px",
                  color: "#757575"
                }}
                id="appendsavelocation"
              >
                {selectedCategory ? selectedCategory?.name : "Filter By"}
              </span>
              <span
                className="v3_togglebtn"
                style={{
                  transform: isOpendrop ? 'rotate(135deg)' : 'rotate(-45deg)',
                }}
              ></span>
              <span className="filtericonsetting_v3"><Icon id="v3_filter" /></span>
            </div>
            {isOpendrop && (
              <div className="v3_menuItems_taskbar" >
                <div style={{ padding: '10px', }} >

                  <div className="v3_dropdown_optionvalue">
                    <div style={{ width: "100%" }}>
                      <p className="category_name" onClick={() => SortByCategory(null)}  >Clear Filter</p>
                      {promptsData.prompt_category.map((category, index) => (
                        <>
                          {category.type === "agency-admin" &&
                            <p className="category_name" onClick={() => SortByCategory(category)} >{category.name}</p>
                          }
                        </>))}

                    </div>
                  </div>
                </div>

              </div>
            )}
          </div>
        </div>

        <div className="v3_tab_navigation">
          <button
            className={`v3_tab_button ${activeTab === 'task' ? 'active_tab' : ''}`}
            onClick={() => switchTab('task')}
          >
            Tasks
          </button>
          <button
            className={`v3_tab_button ${activeTab === 'chat' ? 'active_tab' : ''}`}
            onClick={() => switchTab('chat')}
          >
            Chats
          </button>
          <button
            className={`v3_tab_button ${activeTab === 'assistant' ? 'active_tab' : ''}`}
            onClick={() => switchTab('assistant')}
          >
            Agents
          </button>
        </div>

        {activeTab === 'task' && (
          <>
            <h2 className="result_3">{filteredPrompts?.length} Tasks</h2>
            {filteredPrompts?.map((prompt, index) => (
              <div key={index} className="box_1" onClick={() => LoadTaskonchat(prompt.title, prompt.prompt_value, closeNav())}>
                <div style={{ marginBottom: "7px", width: "90%" }}>
                  <b className="box_1_b">{truncateWords(prompt.title, 18)}</b>
                </div>
                <p className="box_1_p">{truncateWords(prompt.prompt_value, 18)}</p>
              </div>
            ))}
          </>
        )}

        {/* Chat Results */}
        {activeTab === 'chat' && (
          <>
            <h2 className="result_3">{filteredChat?.length} Chats</h2>
            {filteredChat?.map((prompt, index) => (
              <Link
                to={`/chat/${prompt.id}`}
                onClick={() => setUserAsUnread(prompt.id, closeNav())}
              >
                <div key={index} className="box_1">
                  <div style={{ marginBottom: "7px" }}>
                    <b className="box_1_b">{truncateWords(prompt.name, 15)}</b>
                  </div>
                  <p className="box_1_p">{truncateWords(prompt.name, 15)}</p>
                </div>
              </Link>
            ))}
          </>
        )}

        {/* Assistant Results */}
        {activeTab === 'assistant' && (
          <>
            <h2 className="result_3">{filteredAssistan?.length} Agents</h2>
            {filteredAssistan?.map((item, index) => (
              <div onClick={() => updateAssistant(item, closeNav())}>
                <div key={index} className="box_1">
                  <div style={{ marginBottom: "7px" }}>
                    <b className="box_1_b">{item.assistant_name}</b>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {activeTab === "all" && <div>
          <h2 className="result_3">{filteredPrompts?.length} Task</h2>

          {filteredPrompts?.map((prompt, index) => (

            <div key={index} className="box_1" onClick={() => LoadTaskonchat(prompt.title, prompt.prompt_value, closeNav())}>
              <div style={{ marginBottom: "7px", width: "90%" }}>
                <b className="box_1_b">{truncateWords(prompt.title, 18)}</b>
              </div>

              <p className="box_1_p">{truncateWords(prompt.prompt_value, 18)}</p>
            </div>
          ))}

          <h2 className="result_3">{filteredChat?.length} Chats</h2>

          {filteredChat?.map((prompt, index) => (
            <Link

              to={`/chat/${prompt.id}`}
              onClick={() => setUserAsUnread(prompt.id, closeNav())}
            >
              <div key={index} className="box_1">
                <div style={{ marginBottom: "7px" }}>
                  <b className="box_1_b">{truncateWords(prompt.name, 15)}</b>
                </div>
                <p className="box_1_p">{truncateWords(prompt.name, 15)}</p>
              </div></Link>
          ))}

          <h2 className="result_3">{filteredAssistan?.length} Assistants</h2>

          {filteredAssistan?.map((item, index) => (
            <div

              onClick={() => updateAssistant(item, closeNav())}
            >
              <div key={index} className="box_1">
                <div style={{ marginBottom: "7px" }}>
                  <b className="box_1_b">{item.assistant_name}</b>
                </div>
              </div>
            </div>
          ))}
        </div>}

      </div>


      <div className="v3_sec_sidebar__openbtn" onClick={() => toggleSidebar('search')}>
        {text}
      </div>
    </div >
  );
};

export default Sidebar2;
