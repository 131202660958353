import React, { useState } from "react";
import Icon from "components/Icon";
import axios from "axios";
import "../AddPromptModal/index.css";
import env from "config";
import { useUsersContext } from "context/usersContext";
const BASE_URL = `${env.API_URL}/v1`;

const AddSubCategoryModal = ({ onClose, categories, refreshData }) => {
  const { SSO } = useUsersContext();

  const [processing, setProcessing] = useState(false);
  const [error, setError] = useState();
  const [subCategoryName, setSubCategoryName] = useState();
  const [category_id, setcategory_id] = useState();

  const handleCategorySubmit = async () => {
    setProcessing(true);
    setError(false);

    if (subCategoryName === "" || subCategoryName === undefined) {
      setError(true);
      setProcessing(false);
      return false;
    }

    const response = await axios.post(`${BASE_URL}/prompts/subcategory`, {
      name: subCategoryName,
      category_id: category_id,
      userId: SSO?.userUUID,
      locationId: SSO?.id,
      type: "user",
    });

    setProcessing(false);
    if (response) {
      onClose();
      if (refreshData) {
        refreshData();
      }
    }
  };
  return (
    <div style={{ position: "relative" }}>
      <p
        style={{
          position: "absolute",
          right: 0,
          width: "12px",
          height: "12px",
          cursor: "pointer",
        }}
        onClick={onClose}
      >
        <Icon id="close" />
      </p>
      <p className="modalText">Add Sub Category</p>
      <div className="formGroup" style={{ marginBottom: "20px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <label>Select Parent Category</label>
        </div>
        <select
          className="flex-1 modalInputs"
          style={{ width: "100%" }}
          name="promptCategory"
          value={category_id}
          onChange={(e) => {
            setcategory_id(e.target.value);
          }}
        >
          <option selected disabled>
            Select Category
          </option>
          {categories?.map((item, index) => (
            <option value={item._id}>{item.name}</option>
          ))}
        </select>
      </div>
      <div className="formGroup" style={{ marginBottom: "20px" }}>
        <label>Name</label>
        <input
          type="text"
          name="name"
          placeholder="Sub Category Name"
          className="flex-1 modalInputs"
          onChange={(e) => setSubCategoryName(e.target.value)}
        />
        {error && (
          <small style={{ color: "red" }}>Sub Category name required!</small>
        )}
      </div>
      <button
        className="disagreeBtn"
        style={{
          margin: 0,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          opacity: processing ? 0.6 : 1,
        }}
        disabled={processing}
        onClick={handleCategorySubmit}
      >
        {processing && <div className="spinner"></div>}
        Save
      </button>
    </div>
  );
};

export default AddSubCategoryModal;
